/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchResource } from '../utils/fetch-wrapper';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    error: null,
    loading: false,
    auth: { status: false, token: '' },
    user: null,
    payment: false,
  },
  reducers: {
    loginStart: (state) => {
      state.loading = true;
      state.auth = false;
      state.user = null;
    },
    loginSuccess: (state, { payload }) => {
      const { userData, auth } = payload;
      state.user = userData.data;
      state.auth = auth;
      state.loading = false;
    },

    loginFailed: (state, { payload }) => {
      state.user = null;
      state.error = payload;
      state.loading = false;
    },
    setUserToState: (state, { payload }) => {
      state.user = payload;
    },
    setUserProfile: (state, { payload }) => {
      state.userProfile = payload;
    },
    setNotifications: (state, { payload }) => {
      state.notifications = payload;
    },
    setAuthToState: (state, { payload }) => {
      if (payload) {
        state.auth = payload;
      }
    },
    logout: (state) => {
      state.user = null;
      state.auth = { status: false, token: null };
      localStorage.removeItem('user');
      localStorage.removeItem('auth');
    },
    setpayment: (state, { payload }) => {
      state.payment = payload;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailed,
  setUserToState,
  setNotifications,
  setUserProfile,
  setAuthToState,
  setpayment,
  logout,
} = authSlice.actions;

const loginUser = (email, password) => {
  return fetchResource('signin', 'POST', {
    body: {
      email,
      password,
    },
  });
};

export const loginUserThunk = (user) => async (dispatch) => {
  dispatch(loginStart());
  loginFailed('');
  loginUser(user.email, user.password)
    .then((userData) => {
      if (userData.error === undefined) {
        localStorage.setItem('user', JSON.stringify(userData));
        const auth = {
          status: true,
          token: userData.token,
        };
        localStorage.setItem('auth', JSON.stringify(auth));
        dispatch(loginSuccess({ userData, auth }));
      } else {
        // eslint-disable-next-line prefer-template
        dispatch(loginFailed('* ' + userData.error));
      }
    })
    .catch((err) => {
      dispatch(loginFailed(err?.response?.message));
    });
};

export const selectUser = (state) => state.auth.user;
export const selectAuth = (state) => state.auth.auth;
export const selectLoading = (state) => state.auth.loading;
export const selectError = (state) => state.auth.error;
export const selectPayent = (state) => state.auth.payment;
export const notifications = (state) => state.auth.notifications;
export const userProfile = (state) => state.auth.userProfile;
export const selectLoginSuccessfull = (state) => state.auth.loginSuccessfull;

export default authSlice.reducer;
