import React, { useState } from 'react';
import styled from 'styled-components';
import SignUpForm from './signupForm';
import LoginForm from './loginForm';
import ForgotForm from './forgotpassForm';
import NeedHelp from './needHelp';

export default function Login() {
  const [login, setLogin] = useState(true);
  const [signup, setsignup] = useState(false);
  const [forgot, setforgot] = useState(false);
  const [help, setHelp] = useState(false);

  const handleSignup = () => {
    setLogin(false);
    setsignup(true);
    setforgot(false);
    setHelp(false);
  };

  const handleLogin = () => {
    setsignup(false);
    setLogin(true);
    setforgot(false);
    setHelp(false);
  };

  const handleForgotpass = () => {
    setsignup(false);
    setLogin(false);
    setforgot(true);
    setHelp(false);
  };
  const handleNeedHelp = () => {
    setsignup(false);
    setLogin(false);
    setforgot(false);
    setHelp(true);
  };

  return (
    <MainDiv>
      <FooterDiv>
        <img alt="geescore footer" src="./Vector6.png" height={200} width="100%" />
      </FooterDiv>

      <MainContentDiv>
        <HeaderDiv>
          <img alt="geescore logo" src={'./geescore-logo.png'} />
        </HeaderDiv>

        <VectorDiv>
          <VectorImg alt="geescore" height={400} src={'./Group.png'} />
        </VectorDiv>
        {login && (
          <LoginForm
            handleSignup={handleSignup}
            handleForgotpass={handleForgotpass}
            handleNeedHelp={handleNeedHelp}
          />
        )}
        {signup && <SignUpForm handleLogin={handleLogin} />}
        {forgot && <ForgotForm />}
        {help && <NeedHelp />}
      </MainContentDiv>
    </MainDiv>
  );
}

const MainDiv = styled.div`
  height: -webkit-fill-availabl;
  background-color: rgba(30, 54, 167, 0.06);
  position: relative;
  min-height: 100vh;
`;
const MainContentDiv = styled.div`
  height: -webkit-fill-available;
  position: relative;
  margin: 0rem 6rem;
  padding: 3rem 0rem;
  @media only screen and (max-width: 600px) {
    margin: 0rem 2rem;
    padding-bottom: 0rem;
  }
`;

const HeaderDiv = styled.div`
  position: absolute;
  background: initial;
`;
const FooterDiv = styled.div`
  position: absolute;
  background: initial;
  bottom: 0;
  bottom: 0;
  width: -webkit-fill-available;
  width: -moz-available;
  margin-bottom: -2.5px;
`;
const VectorDiv = styled.div`
  //padding: 8rem 2rem 5rem 0rem;
  top: 23vh;
  margin-top: 5rem;
  position: absolute;
  @media only screen and (min-width: 1400px) {
    top: 32vh;
  }
`;

const VectorImg = styled.img`
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
