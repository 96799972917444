import React from 'react';
import styled from 'styled-components';
import ResetPassForm from './resetPassForm';

export default function ResetPass() {
  return (
    <MainDiv>
      <FooterDiv>
        <img alt="geescore footer" src="./Vector6.png" height={200} width="100%" />
      </FooterDiv>

      <MainContentDiv>
        <HeaderDiv>
          <img alt="geescore logo" src="./geescore-logo.png" />
        </HeaderDiv>

        <VectorDiv>
          <VectorImg alt="geescore" height={400} src="./Group.png" />
        </VectorDiv>
        <ResetPassForm />
      </MainContentDiv>
    </MainDiv>
  );
}

const MainDiv = styled.div`
  height: -webkit-fill-availabl;
  background-color: rgba(30, 54, 167, 0.06);
`;
const MainContentDiv = styled.div`
  height: -webkit-fill-available;
  position: relative;
  margin: 0rem 6rem;
  padding: 3rem 0rem;
  @media only screen and (max-width: 600px) {
    margin: 0rem 2rem;
    padding-bottom: 0rem;
  }
`;

const HeaderDiv = styled.div`
  position: absolute;
  background: initial;
`;
const FooterDiv = styled.div`
  position: absolute;
  background: initial;
  bottom: 0;
  bottom: 0;
  width: -webkit-fill-available;
  width: -moz-available;
  margin-bottom: -2.5px;
`;
const VectorDiv = styled.div`
  //padding: 8rem 2rem 5rem 0rem;
  top: 23vh;
  margin-top: 5rem;
  position: absolute;
  @media only screen and (min-width: 1400px) {
    top: 32vh;
  }
`;

const VectorImg = styled.img`
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
