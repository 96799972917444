/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import * as EmailValidator from 'email-validator';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { selectError } from '../app/authSlice';
import { fetchResource } from '../utils/fetch-wrapper';
import {
  FormWrapDiv,
  FormDiv,
  InputWrapDiv,
  InputImg,
  InputDiv,
  Button,
  InlineInputDiv,
} from './login.components';

export default function ResetPassForm() {
  const error = useSelector(selectError);
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [token, setToken] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emptyError, setEmptyError] = useState(false);
  const [resetApiError, setresetApiError] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [SnakeBarMessage, setSnakeBarMessage] = React.useState(false);

  function Alert(el) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MuiAlert elevation={6} variant="filled" {...el} />;
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleSubmit = () => {
    if (email.length > 0 && pass.length > 0 && token.length > 0) {
      setEmptyError(false);
      if (EmailValidator.validate(email)) {
        setEmailError(false);
        fetchResource('reset-password', 'POST', {
          body: {
            email,
            password: pass,
            token,
          },
        }).then((response) => {
          if (response.has_error) {
            setresetApiError(response.error);
          } else {
            setOpen(true);
            setEmail('');
            setPass('');
            setToken('');
            setEmailError(false);
            setEmptyError(false);
            setresetApiError('');
            setSnakeBarMessage(response.message);
          }
        });
      } else setEmailError(true);
    } else {
      setEmptyError(true);
    }
  };
  useEffect(() => {
    const { email: userEmail, token: userToken } = queryString.parse(window.location.search);
    setToken(userToken || '');
    setEmail(userEmail || '');
  }, []);

  return (
    <FormWrapDiv>
      <FormDiv>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {SnakeBarMessage}
          </Alert>
        </Snackbar>
        <h1 style={{ color: '#273272', fontFamily: 'sans-serif' }}>
          Reset your Geescore™ account password
        </h1>
        <div
          style={{
            backgroundColor: '#ff9f23',
            height: '0.3rem',
            width: '5rem',
          }}
        />

        <div style={{ alignItems: 'center', marginTop: '0.7rem' }}>
          <label style={{ color: '#676767' }}>Not having account?</label>
          <a
            href="/"
            style={{
              marginLeft: '5px',
              color: '#273272',
            }}
          >
            Signup Here
          </a>
        </div>

        <div style={{ marginTop: '1.5rem' }}>
          <label style={{ color: '#4d4d4d' }}>Email Address</label>
          <InputWrapDiv>
            <InputImg src="./input-icon1.svg" />
            <InputDiv
              placeholder="Email ID"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputWrapDiv>
          {emailError && (
            <label style={{ color: 'red', marginLeft: '5px', marginTop: '5px' }}>
              Not a valid email
            </label>
          )}
        </div>

        <div style={{ marginTop: '0.8rem' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <label style={{ color: '#4d4d4d' }}>Enter Token</label>
            <label style={{ color: '#767676', cursor: 'pointer' }}>Need Help?</label>
          </div>
          <InputWrapDiv>
            <InputImg src="./input-icon3.svg" />
            <InputDiv
              placeholder="Enter Token"
              type="text"
              value={token}
              onChange={(e) => setToken(e.target.value)}
            />
          </InputWrapDiv>
        </div>

        <div style={{ marginTop: '0.8rem' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <label style={{ color: '#4d4d4d' }}>New Password</label>
          </div>
          <InputWrapDiv>
            <InputImg src="./input-icon2.svg" />
            <InputDiv
              placeholder="New Password"
              type="password"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
          </InputWrapDiv>
        </div>

        <div style={{ marginTop: '2em' }} />
        {error && <label style={{ color: 'red', marginLeft: '5px' }}>{error}</label>}
        {emptyError && (
          <label style={{ color: 'red', marginLeft: '5px' }}>
            Email or Password should not be empty
          </label>
        )}
        {resetApiError && (
          <label style={{ color: 'red', marginLeft: '5px' }}>{resetApiError}</label>
        )}

        <InlineInputDiv>
          <Button onClick={() => handleSubmit()}>Reset Password</Button>
          {/* <Button bgColor="#ffffff" tColor="#407bff" onClick={() => props.handleSignup()}>
            Forgot Password
          </Button> */}
        </InlineInputDiv>
      </FormDiv>
    </FormWrapDiv>
  );
}
