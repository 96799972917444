import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from './components/spinner/spinner';
import { setUserToState, setAuthToState, selectAuth } from './app/authSlice';
import OpenRoutes from './initialPages/router';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const loadAuthenticatedApp = () => import('./AuthenticatedApp');
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
// const LoginPage = React.lazy(() => import('./initialPages/login'));
console.log('Testing');
function App() {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);

  const setAuth = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const auth = JSON.parse(localStorage.getItem('auth'));
    if (auth) {
      dispatch(setUserToState(user.data));
      dispatch(setAuthToState(auth));
    }
  };

  React.useEffect(() => {
    setAuth();
    loadAuthenticatedApp();
  }, []);
  return (
    <React.Suspense fallback={<Spinner />}>
      <ToastContainer />
      {/* <AuthenticatedApp /> */}
      {auth.status ? <AuthenticatedApp /> : <OpenRoutes />}
    </React.Suspense>
  );
}

export default App;
