/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import * as EmailValidator from 'email-validator';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { fetchResource } from '../utils/fetch-wrapper';
import {
  FormWrapDiv,
  FormDiv,
  InputWrapDiv,
  InputImg,
  InputDiv,
  Button,
  InlineInputDiv,
  ResponsiveInputDiv,
} from './login.components';

export default function SignUpForm(props) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emptyError, setEmptyError] = useState(false);
  const [passError, setPassError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [SnakeBarMessage, setSnakeBarMessage] = React.useState(false);

  function Alert(el) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MuiAlert elevation={6} variant="filled" {...el} />;
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = (dpName, userEmail, password) => {
    // console.log('dpName', dpName);
    // console.log('userEmail', userEmail);
    // console.log('password', password);
    fetchResource('signup', 'POST', {
      body: {
        dpName,
        userEmail,
        // user_type,
        password,
      },
    })
      .then((response) => {
        // console.log(response);
        if (response.error === undefined) {
          setError(false);
          setName('');
          setPass('');
          setConfirmPass('');
          setEmail('');
          setOpen(true);
          setSnakeBarMessage('User successfully created');
        } else {
          setError(response.error);
        }
      })
      .catch((err) => {
        setOpen(true);
        setSnakeBarMessage(err ? err.message : 'Something went wrong');
        // console.log(err);
      });
  };

  const checkSubmit = () => {
    if (email.length > 0 && pass.length > 0 && name.length > 0) {
      // && userType.length > 0
      setEmptyError(false);
      if (!EmailValidator.validate(email)) {
        setEmailError(true);
      } else if (pass !== confirmPass) {
        setPassError(true);
      } else {
        setPassError(false);
        setEmailError(false);
        handleSubmit(name, email, pass); // org_user
      }
    } else {
      setEmptyError(true);
    }
  };

  return (
    <FormWrapDiv>
      <FormDiv>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {SnakeBarMessage}
          </Alert>
        </Snackbar>
        <h1 style={{ color: '#273272', fontFamily: 'sans-serif' }}>
          Join Geescore™ for FREE and explore
        </h1>
        <div
          style={{
            backgroundColor: '#ff9f23',
            height: '0.3rem',
            width: '5rem',
          }}
        />

        <div style={{ alignItems: 'center', marginTop: '0.7rem' }}>
          <label style={{ color: '#676767' }}>Already having account?</label>
          <a
            href="/login"
            style={{
              marginLeft: '5px',
              color: '#273272',
            }}
          >
            Login Here
          </a>
        </div>

        <div style={{ marginTop: '2rem' }}>
          <label style={{ color: '#4d4d4d' }}>First Name</label>
          <InputWrapDiv>
            <InputImg src="./input-icon1.svg" />
            <InputDiv
              placeholder="Enter Full Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </InputWrapDiv>

          <div style={{ marginTop: '1rem' }}>
            <label style={{ color: '#4d4d4d' }}>Your Email Addresss</label>
            <InputWrapDiv>
              <InputImg src="./input-icon4.svg" />
              <InputDiv
                placeholder="Enter Email Address"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputWrapDiv>
          </div>

          {emailError && (
            <label style={{ color: 'red', marginLeft: '5px', marginTop: '5px' }}>
              Not a valid email
            </label>
          )}
          {/* <div style={{ marginTop: '1rem' }}>
            <label style={{ color: '#4d4d4d' }}>Your user type</label>
            <InputWrapDiv>
              <InputImg src="./input-icon1.svg" />
              <InputDiv
                placeholder="Enter user type"
                type="text"
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
              />
            </InputWrapDiv>
          </div> */}
          <InlineInputDiv>
            <ResponsiveInputDiv>
              <label style={{ color: '#4d4d4d' }}>Enter Password</label>
              <InputWrapDiv>
                <InputImg src="./input-icon2.svg" />
                <InputDiv
                  placeholder="Enter Password"
                  type="password"
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                />
              </InputWrapDiv>
            </ResponsiveInputDiv>

            <ResponsiveInputDiv>
              <label style={{ color: '#4d4d4d' }}>Re-enter Password</label>
              <InputWrapDiv>
                <InputImg src="./input-icon2.svg" />
                <InputDiv
                  placeholder="Re-enter Password"
                  type="password"
                  value={confirmPass}
                  onChange={(e) => setConfirmPass(e.target.value)}
                />
              </InputWrapDiv>
            </ResponsiveInputDiv>
          </InlineInputDiv>
        </div>
        {passError && (
          <label style={{ color: 'red', marginLeft: '5px' }}>Password not matched</label>
        )}
        <div style={{ marginTop: '3em' }} />
        {error && <label style={{ color: 'red', marginLeft: '5px' }}>{error}</label>}
        {emptyError && (
          <label style={{ color: 'red', marginLeft: '5px' }}>Fields should not be empty</label>
        )}

        <InlineInputDiv>
          <Button onClick={() => checkSubmit()} style={{ cursor: 'pointer' }}>
            Join Now
          </Button>
          <Button
            bgColor="#ffffff"
            tColor="#407bff"
            onClick={() => props.handleLogin()}
            style={{ cursor: 'pointer' }}
          >
            Login
          </Button>
        </InlineInputDiv>
      </FormDiv>
    </FormWrapDiv>
  );
}

SignUpForm.propTypes = {
  // eslint-disable-next-line react/require-default-props
  handleLogin: PropTypes.func,
};
