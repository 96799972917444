const API_URL = 'https://api.geedash.com';
export const JOB_SCORE_API =
  'https://qgeo0umwx3.execute-api.us-east-2.amazonaws.com/dev-v1/job-score';
export const GET_JOB_SCORES =
  'https://api.geehr.com/v2/seeker-mgmt/job-seeker/scoring-components/#seeker_id/#jobpost_id/#resume_id/#threshold'; //get
// Custom API error to throw
function ApiError(message, data, status) {
  let response = null;
  let isObject = false;

  // We are trying to parse response
  try {
    response = JSON.parse(data);
    isObject = true;
  } catch (e) {
    response = data;
  }

  this.response = response;
  this.message = message;
  this.status = status;
  this.toString = function () {
    return `${this.message}\nResponse:\n${isObject ? JSON.stringify(this.response, null, 2) : this.response
      }`;
  };
}

// API wrapper function
// const fetchResource = (path, userOptions = {}) => {
export const fetchResource = (path, method = 'GET', userOptions = {}, baseUrl = API_URL) => {
  // Define default options
  const defaultOptions = {};
  const { data = {} } = JSON.parse(localStorage.getItem('user')) || {};
  // Define default headers
  const defaultHeaders = { 'Content-Type': 'application/json' };

  const options = {
    method,
    // Merge options
    ...defaultOptions,
    ...userOptions,
    // Merge headers
    headers: {
      ...defaultHeaders,
      authorization: data.token ? `Bearer ${data.token}` : '',
      ...userOptions.headers,
    },
  };

  // Build Url
  const url = `${baseUrl}/${path}`;

  // Detect is we are uploading a file
  const isFile = options.body instanceof File;

  // Stringify JSON data
  // If body is not a file
  if (options.body && typeof options.body === 'object' && !isFile) {
    options.body = JSON.stringify(options.body);
  }

  // Variable which will be used for storing response
  let response = null;

  return (
    fetch(url, options)
      .then((responseObject) => {
        // Saving response for later use in lower scopes
        response = responseObject;

        // HTTP unauthorized
        if (response.status === 401) {
          // Handle unauthorized requests
          // Maybe redirect to login page?
        }

        // Check for error HTTP error codes
        if (response.status < 200 || response.status >= 300) {
          // Get response as text
          return response.text();
        }

        // Get response as json
        return response.json();
      })
      // "parsedResponse" will be either text or javascript object depending if
      // "response.text()" or "response.json()" got called in the upper scope
      .then((parsedResponse) => {
        // Check for HTTP error codes
        if (response.status < 200 || response.status >= 300) {
          // Throw error
          throw parsedResponse;
        }

        // Request succeeded
        return parsedResponse;
      })
      .catch((error) => {
        // Throw custom API error
        // If response exists it means HTTP error occured
        if (response) {
          throw new ApiError(
            `Request failed with status ${response.status}.`,
            error,
            response.status,
          );
        } else {
          throw new ApiError(error.toString(), null, 'REQUEST_FAILED');
        }
      })
  );
};
