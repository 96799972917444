/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import * as EmailValidator from 'email-validator';
// import { useSelector } from 'react-redux';
// import { selectError } from '../app/authSlice';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { fetchResource } from '../utils/fetch-wrapper';
import {
  FormWrapDiv,
  FormDiv,
  InputWrapDiv,
  InputImg,
  InputDiv,
  Button,
  InlineInputDiv,
} from './login.components';

export default function ForgotForm() {
  const [error, setError] = useState('');
  // const [success, setSuccess] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emptyError, setEmptyError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [SnakeBarMessage, setSnakeBarMessage] = React.useState(false);
  function Alert(el) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MuiAlert elevation={6} variant="filled" {...el} />;
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const handleSubmit = () => {
    if (email.length > 0) {
      setEmptyError(false);
      if (EmailValidator.validate(email)) {
        setEmailError(false);
        fetchResource('forgot-password', 'POST', {
          body: {
            email,
          },
        })
          .then((response) => {
            if (response.has_error) {
              setError(response.error);
            } else {
              // setSuccess(response.message);
              setError('');
              setEmptyError(false);
              setEmailError(false);
              setOpen(true);
              setSnakeBarMessage(response.message);
              setEmail('');
            }
          })
          .catch((err) => setError(err));
      } else setEmailError(true);
    } else {
      setEmptyError(true);
    }
  };

  return (
    <FormWrapDiv>
      <FormDiv>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {SnakeBarMessage}
          </Alert>
        </Snackbar>
        <h1 style={{ color: '#273272', fontFamily: 'sans-serif' }}>
          Login to your Geescore™ account
        </h1>
        <div
          style={{
            backgroundColor: '#ff9f23',
            height: '0.3rem',
            width: '5rem',
          }}
        />

        <div style={{ alignItems: 'center', marginTop: '0.7rem' }}>
          <label style={{ color: '#676767' }}>Not having account?</label>
          <a
            href="/"
            style={{
              marginLeft: '5px',
              color: '#273272',
            }}
          >
            Signup Here
          </a>
        </div>

        <div style={{ marginTop: '2rem' }}>
          <label style={{ color: '#4d4d4d' }}>Email Address</label>
          <InputWrapDiv>
            <InputImg src="./input-icon1.svg" />
            <InputDiv
              placeholder="Email ID"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputWrapDiv>
          {emailError && (
            <label style={{ color: 'red', marginLeft: '5px', marginTop: '5px' }}>
              Not a valid email
            </label>
          )}
        </div>

        <div style={{ marginTop: '3em' }} />
        {error && <label style={{ color: 'red', marginLeft: '5px' }}>{error}</label>}
        {/* {success && <label style={{ color: 'green', marginLeft: '5px' }}>{success}</label>} */}
        {emptyError && (
          <label style={{ color: 'red', marginLeft: '5px' }}>Email should not be empty</label>
        )}

        <InlineInputDiv style={{ marginTop: '30%' }}>
          <Button onClick={() => handleSubmit()} style={{ cursor: 'pointer' }}>
            Forgot Password
          </Button>
        </InlineInputDiv>
      </FormDiv>
    </FormWrapDiv>
  );
}
