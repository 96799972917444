import styled from 'styled-components';

const FormWrapDiv = styled.div`
  display: flex;
  width: -webkit-fill-available;
  justify-content: flex-end;
`;

const FormDiv = styled.div`
  width: 32vw;
  height: auto;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  padding: 2rem 3rem;
  margin: 3vh 0rem;
  position: relative;
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 70%;
    margin: 10vh 0rem;
  }
`;

const InputWrapDiv = styled.div`
  margin-top: 0.6rem;
  border: 1px solid rgba(201, 201, 201, 0.6);
  display: flex;
  align-items: center;
  border-radius: 4px;
`;

const InputImg = styled.img`
  margin-left: 1rem;
`;
const InputDiv = styled.input`
  border: none;
  margin-left: 1rem;
  height: 2.5rem;
  color: black;
  width: 100%;
  outline: none;
  font-size: 14px;
`;

const Button = styled.button`
  padding: 1rem 0rem;
  width: 48.5%;
  font-size: 18px;
  border: solid 1px #407bff;
  background-color: ${(props) => props.bgColor || '#407bff'};
  border-radius: 6px;
  color: ${(props) => props.tColor || '#ffffff'};
  outline: none;
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-top: 1rem;
  }
  &:hover {
    background-color: #273273;
    color: white;
  }
`;

const InlineInputDiv = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const ResponsiveInputDiv = styled.div`
  width: 48.5%;
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-top: 1rem;
  }
`;

export {
  FormWrapDiv,
  FormDiv,
  InputWrapDiv,
  InputImg,
  InputDiv,
  Button,
  InlineInputDiv,
  ResponsiveInputDiv,
};
