import React, { useState } from 'react';
import {
  FormWrapDiv,
  FormDiv,
  InputWrapDiv,
  InputImg,
  InputDiv,
  Button,
  InlineInputDiv,
} from './login.components';

export default function NeedHelp() {
  return (
    <FormWrapDiv>
      <FormDiv>
        <h1 style={{ color: '#273272', fontFamily: 'sans-serif' }}>
          Login to your Geescore™ account
        </h1>
        <div
          style={{
            backgroundColor: '#ff9f23',
            height: '0.3rem',
            width: '5rem',
          }}
        />
        <div style={{ alignItems: 'center', marginTop: '0.7rem' }}>
          <a
            href="/"
            style={{
              marginLeft: '5px',
              color: '#273272',
            }}
          >
            Back To Login
          </a>
        </div>
        <br /> <br />
        <div>Contact us at geescore_support@geemode.com</div>
      </FormDiv>
    </FormWrapDiv>
  );
}
