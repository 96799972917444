/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import * as EmailValidator from 'email-validator';
import { useSelector, useDispatch } from 'react-redux';
import { loginUserThunk, selectError } from '../app/authSlice';

import {
  FormWrapDiv,
  FormDiv,
  InputWrapDiv,
  InputImg,
  InputDiv,
  Button,
  InlineInputDiv,
} from './login.components';

export default function LoginForm(props) {
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [token, setToken] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emptyError, setEmptyError] = useState(false);

  const handleSubmit = () => {
    if (email.length > 0 && pass.length > 0) {
      setEmptyError(false);
      if (EmailValidator.validate(email)) {
        setEmailError(false);
        dispatch(loginUserThunk({ email, password: pass }));
      } else setEmailError(true);
    } else {
      setEmptyError(true);
    }
  };

  return (
    <FormWrapDiv>
      <FormDiv>
        <h1 style={{ color: '#273272', fontFamily: 'sans-serif' }}>
          Login to your Geescore™ account
        </h1>
        <div
          style={{
            backgroundColor: '#ff9f23',
            height: '0.3rem',
            width: '5rem',
          }}
        />

        <div style={{ alignItems: 'center', marginTop: '0.7rem' }}>
          <label style={{ color: '#676767' }}>No Account?</label>
          <a
            // eslint-disable-next-line no-script-url
            href="javascript:void(0);"
            style={{
              marginLeft: '5px',
              color: '#273272',
            }}
            onClick={() => props.handleSignup()}
          >
            Signup Here
          </a>
        </div>

        <div style={{ marginTop: '2rem' }}>
          <label style={{ color: '#4d4d4d' }}>Username / Email Address</label>
          <InputWrapDiv>
            <InputImg src="./input-icon1.svg" />
            <InputDiv
              placeholder="Enter Username / Email ID"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputWrapDiv>
          {emailError && (
            <label style={{ color: 'red', marginLeft: '5px', marginTop: '5px' }}>
              Not a valid email
            </label>
          )}
          <div style={{ marginTop: '1rem' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <label style={{ color: '#4d4d4d' }}>Enter Password</label>
              <label
                style={{ color: '#767676', cursor: 'pointer' }}
                onClick={() => props.handleForgotpass()}
              >
                Forgot password?
              </label>
            </div>
            <InputWrapDiv>
              <InputImg src="./input-icon2.svg" />
              <InputDiv
                placeholder="Enter Password"
                type="password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
              />
            </InputWrapDiv>
          </div>

          {/* <div style={{ marginTop: '1rem' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <label style={{ color: '#4d4d4d' }}>Enter Access Token</label>
              <label
                style={{ color: '#767676', cursor: 'pointer' }}
                onClick={() => props.handleNeedHelp()}
              >
                Need Help?
              </label>
            </div>
            <InputWrapDiv>
              <InputImg src="./input-icon3.svg" />
              <InputDiv
                placeholder="Enter Password"
                type="password"
                value={token}
                onChange={(e) => setToken(e.target.value)}
              />
            </InputWrapDiv>
          </div> */}
        </div>

        <div style={{ marginTop: '1em' }} />
        {error && <label style={{ color: 'red', marginLeft: '5px' }}>{error}</label>}
        {emptyError && (
          <label style={{ color: 'red', marginLeft: '5px' }}>
            Email or Password should not be empty
          </label>
        )}

        <InlineInputDiv>
          <Button onClick={() => handleSubmit()} style={{ cursor: 'pointer' }}>
            Login
          </Button>
          <Button
            bgColor="#ffffff"
            tColor="#407bff"
            style={{ cursor: 'pointer' }}
            onClick={() => props.handleSignup()}
          >
            Signup
          </Button>
        </InlineInputDiv>
      </FormDiv>
    </FormWrapDiv>
  );
}
